import { dev } from "$app/environment";
import { PUBLIC_SUPABASE_URL, PUBLIC_VERCEL_ENV } from "$env/static/public";
import * as Sentry from "@sentry/sveltekit";
import { supabaseIntegration } from "@supabase/sentry-js-integration";
import { SupabaseClient } from "@supabase/supabase-js";
import type { HandleClientError } from "@sveltejs/kit";

export function initSentry(dsn: string) {
  if (dev) return;
  if (Sentry.getClient() !== undefined) return; // already initialized

  const config: Sentry.BrowserOptions = {
    dsn,
    // debug: true,
    environment: PUBLIC_VERCEL_ENV,

    attachStacktrace: true,

    beforeSend(event, hint) {
      const error = hint.originalException as { message?: string } | undefined;
      if (error?.message?.match(/Amplitude Logger/) !== null) {
        console.log("Ignoring Amplitude logger errors:", event);
        return null;
      }
      if (event.exception?.values?.[0]?.mechanism?.handled === true) {
        console.log("Handled error:", event);
        return null;
      }
      return event;
    },

    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ["error", "assert"],
      }),
      Sentry.extraErrorDataIntegration(),
      supabaseIntegration(SupabaseClient, Sentry, {
        tracing: true, // Set to true to enable Supabase tracing
        breadcrumbs: true,
        errors: true,
      }),
      /**
       * The below integrations are only enabled when actively debugging performance
       * issues. It can be expensive to run in production.
       */
      Sentry.browserTracingIntegration({
        shouldCreateSpanForRequest: (url) => {
          return !url.startsWith(`${PUBLIC_SUPABASE_URL}/rest`);
        },
      }),
      Sentry.browserProfilingIntegration(),

      /**
       * The below integration is only enabled when actively debugging user issues.
       * It can be expensive to run in production.
       */
      // Sentry.replayIntegration(),
    ],

    /**
     * The below options configure sampling and tracing rates. We tend to leave
     * these disabled when not actively debugging performance issues as they can
     * get expensive quickly.
     */
    profilesSampleRate: 1.0,
    tracesSampler({ name, parentSampled }) {
      if (name.includes("optimizer")) return 1;
      if (name.includes("cart")) return 1;

      if (typeof parentSampled === "boolean") {
        // Propagate parent sampling decision
        return parentSampled;
      }
      // Default decision
      return 0.01;
    },
    /**
     * The below options configure replay rates. We tend to leave these
     * disabled when not actively debugging user issues as they can
     * get expensive quickly.
     */
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  };

  Sentry.init(config);
}

export function wrapHandleError(handler: HandleClientError): HandleClientError {
  if (dev) return handler;
  return Sentry.handleErrorWithSentry(handler);
}
